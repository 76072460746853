import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import {
  Spinner, FilterButtons, SelectComponent, Seo,
} from '../../components'
import CardOffice from './components/card-office'
import './our-offices-styles.scss'

const OurOfficesView = ({
  loading,
  onHandleFilter,
  dataFiltered,
  regions,
  slug,
}) => {
  const { t } = useTranslation()
  const breakpoints = useBreakpoint()
  const options = _.reduce(
    regions,
    (result, tab, idx) => {
      result.push({
        id: idx + 1,
        name: tab.name,
        slug: tab.slug,
        label: tab.name,
        value: tab.slug,
        activeClass: '',
        translateValue: `${110 * (idx + 1)}px`,
      })
      return result
    },
    [
      {
        id: 0,
        name: 'All',
        slug: null,
        value: null,
        label: 'All',
        activeClass: 'filter-active',
        translateValue: '0',
      },
    ],
  )

  if (loading) {
    return <Spinner loading={loading} />
  }

  return (
    <div className="ourOffices__container container-fluid">
      <Seo title={`${t('seo.title')}: Our Offcies`} />
      <div className="ourOffices__box container-xxl">
        <div className="ourOffices__head">
          <h1 className="page-title col-12">{t('our-offices.title')}</h1>
        </div>
        <div className="ourOffices__filters">
          {breakpoints.xs ? (
            <SelectComponent
              blue={true}
              placeholder={t('keyTerms.filter.placeholder', {
                number: options.length - 1,
              })}
              options={options}
              onChange={(e) => onHandleFilter(e.value)}
            />
          ) : (
            <FilterButtons
              slug={slug}
              data={options}
              onHandleFilter={onHandleFilter}
              width={110}
            />
          )}
        </div>
        <div className="ourOffices__section row">
          {_.map(dataFiltered, (office, idx) => (
            <div className="col-12 col-md-4" key={office.name + idx}>
              <CardOffice data={office} />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default OurOfficesView
