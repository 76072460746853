import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { makeRequest } from '../../helpers'
import OurOfficesView from './our-offices-view'

const OurOfficesController = (props) => {
  const { slug } = props
  const [loading, setLoading] = useState(false)
  const [ourOffices, setOurOffices] = useState({})
  const [regions, setRegions] = useState([])
  const [dataFiltered, setDataFiltered] = useState([])
  const { i18n } = useTranslation()

  const getOurOffices = async () => {
    setLoading(true)

    const headers = {
      'Content-Type': 'application/json',
    }
    makeRequest({
      headers,
      endPoint: 'contact/our-offices',
      params: {
        lang: i18n.language,
      },
    })
      .then(({ data }) => {
        setRegions(_.get(data, 'regions'))
        setOurOffices(_.get(data, 'offices'))
        const dataFilterByPlace = slug
          ? _.filter(_.get(data, 'offices'), { region_slug: slug })
          : _.get(data, 'offices')
        setDataFiltered(dataFilterByPlace)
        setLoading(false)
      })
      .catch((error) => {
        console.error(error)
        setLoading(false)
      })
  }

  const onHandleFilter = (group) => {
    if (group) {
      const dataByFilter = _.filter(ourOffices, { region_slug: group })
      setDataFiltered(dataByFilter)
    } else {
      setDataFiltered(ourOffices)
    }
  }

  useEffect(() => {
    getOurOffices()
  }, [i18n.language])

  const viewProps = {
    data: ourOffices,
    loading,
    onHandleFilter,
    dataFiltered,
    regions,
    slug,
  }
  return <OurOfficesView {...viewProps} />
}

export default OurOfficesController
